import { useContext } from 'react';
import { TrackingContext } from '../hooks/useTracking';

const Attribution = () => {
  const tarotHref = 'https://www.tarot.com/?utm_medium=partner&code=huffpost&utm_content=logo&utm_campaign=daily&utm_source=huffpost';
  const { trackExternalClick } = useContext(TrackingContext);

  const onClickAttribution = () => {
    trackExternalClick({
      target_content_id: tarotHref,
      target_content_type: 'url',
      referrer_uri: document.referrer,
      item_name: 'tarot.com',
      item_type: 'text',
    });
  };

  return (
    <div className='attribution'>
      <span>Powered by </span>
        <a
          href={tarotHref}
          target="_blank"
          rel="noreferrer"
          onClick={onClickAttribution}
        >
          Tarot.com
        </a>
    </div>
  );
}

export default Attribution;
