import React from 'react';

function Timestamp({ timestamp }) {
  return (
    <div id='server-timestamp' style={{ display: 'none' }}>
      {timestamp}
    </div>
  );
}

export default Timestamp;
