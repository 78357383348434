import HtmlComponent from './Html'
import Styles from './Styles';
import Scripts from './Scripts';
import Head from 'next/head'
import { capitalize } from '../utils/helpers';
import Ad from './Ad';
import Timestamp from './Timestamp';

import { TrackingContext } from '../hooks/useTracking';;
import { useState, useEffect, useContext } from 'react';
import { useTimeSpentTracking } from '../hooks/useTimeSpentTracking';

export default function Layout({
  navdata,
  children,
  ads,
  sign,
  host,
  timestamp,
  isWebview,
}) {
  const isUK = host === 'www.huffingtonpost.co.uk';
  const { header, footer, leftnav } = navdata;
  const title = `${sign ? `${capitalize(sign)} ` : ''}Horoscopes: Daily, Weekly, Monthly Forecasts | HuffPost${isUK ? ' UK' : ''}`;
  const canonical = `https://${host}/horoscopes${sign ? `/${sign}` : ''}`;

  const {
    trackPageView,
  } = useContext(TrackingContext);

  const [hasTrackedPageView, setHasTrackedPageView] = useState(false);
  useTimeSpentTracking();

  // do tracking when ready
  useEffect(() => {
    if (hasTrackedPageView) { return; }
    trackPageView();
    setHasTrackedPageView(true);
  }, [hasTrackedPageView, trackPageView]);


  return (
    <>
      <Head>
        <title>{title}</title>
        <meta property="og:title" content={title} />
        <meta property="og:url" content={canonical} />
        <meta name="twitter:title" content={title} />
        <link href={canonical} rel="canonical"></link>
      </Head>
      {!isWebview && <Styles navdata={navdata} />}
      {!isWebview && <HtmlComponent html={header.html} />}
      {!isWebview && <HtmlComponent html={leftnav.html} />}
      {!isWebview && <Ad
        className='advertisement top_leaderboard'
        isInject={true}
        code={ads.leaderboard_flex}
      />}
      <Timestamp timestamp={timestamp}/>
      <>{children}</>
      {!isWebview && <HtmlComponent html={footer.html} />}
      {!isWebview && <Scripts navdata={navdata} />}
    </>
  )
}
