const now = new Date();
const thisYear = now.getFullYear();
const nextYear = thisYear + 1;
const lastYear = thisYear - 1;
const isJanuary = now.getMonth() === 0;
const todayUS = new Date(now.toLocaleString('en-US', { timeZone: 'America/New_York' }));
const todayUK = new Date(now.toLocaleString('en-US', { timeZone: 'Europe/London' }));

export default {
  aries: {
    id: 1,
    dayStart: new Date(`${thisYear}-03-21T00:00:00.000Z`),
    dayEnd: new Date(`${thisYear}-04-19T23:59:59.000Z`),
    isTodayUS: function () {
      return todayUS >= this.dayStart && todayUS <= this.dayEnd
    },
    isTodayUK: function () {
      return todayUK >= this.dayStart && todayUK <= this.dayEnd
    },
    metadata: {
      range: 'March 21 - April 19',
      shortRange: 'Mar 21 - Apr 19',
      animal: 'The Ram',
      type: 'Fire',
      planet: 'Mars',
      blurb: 'Aries is the fearless trailblazer of the zodiac. They are passionate, ambitious, and always eager to take the lead. With boundless energy and a competitive spirit, Aries strives to conquer challenges and achieve their goals.'
    }
  },
  taurus: {
    id: 2,
    dayStart: new Date(`${thisYear}-04-20T00:00:00.000Z`),
    dayEnd: new Date(`${thisYear}-05-20T23:59:59.000Z`),
    isTodayUS: function () {
      return todayUS >= this.dayStart && todayUS <= this.dayEnd
    },
    isTodayUK: function () {
      return todayUK >= this.dayStart && todayUK <= this.dayEnd
    },
    metadata: {
      range: 'April 20 - May 20',
      shortRange: 'Apr 20 - May 20',
      animal: 'The Bull',
      type: 'Earth',
      planet: 'Venus',
      blurb: 'Taurus is the dependable rock of the zodiac. They are known for their practicality, patience, and unwavering determination. Grounded and reliable,Taurus values comfort and security and cherishes the finer things in life.'
    }
  },
  gemini: {
    id: 3,
    dayStart: new Date(`${thisYear}-05-21T00:00:00.000Z`),
    dayEnd: new Date(`${thisYear}-06-20T23:59:59.000Z`),
    isTodayUS: function () {
      return todayUS >= this.dayStart && todayUS <= this.dayEnd
    },
    isTodayUK: function () {
      return todayUK >= this.dayStart && todayUK <= this.dayEnd
    },
    metadata: {
      range: 'May 21 - June 20',
      shortRange: 'May 21 - Jun 20',
      animal: 'The Twins',
      type: 'Air',
      planet: 'Mercury',
      blurb: 'Gemini is the curious and versatile communicator of the zodiac. Represented by the twins, they possess a dual nature, which makes them adaptable, witty, and social butterflies. Geminis love to learn and are skilled at seeing both sides of any situation.'
    }
  },
  cancer: {
    id: 4,
    dayStart: new Date(`${thisYear}-06-21T00:00:00.000Z`),
    dayEnd: new Date(`${thisYear}-07-22T23:59:59.000Z`),
    isTodayUS: function () {
      return todayUS >= this.dayStart && todayUS <= this.dayEnd
    },
    isTodayUK: function () {
      return todayUK >= this.dayStart && todayUK <= this.dayEnd
    },
    metadata: {
      range: 'June 21 - July 22',
      shortRange: 'Jun 21 - Jul 22',
      animal: 'The Crab',
      type: 'Water',
      planet: 'The Moon',
      blurb: 'Cancer is the nurturing and empathetic caretaker of the zodiac. They are highly intuitive, compassionate, and deeply connected to their emotions. Caring for family and loved ones is paramount to Cancer\'s happiness.'
    }
  },
  leo: {
    id: 5,
    dayStart: new Date(`${thisYear}-07-23T00:00:00.000Z`),
    dayEnd: new Date(`${thisYear}-08-22T23:59:59.000Z`),
    isTodayUS: function () {
      return todayUS >= this.dayStart && todayUS <= this.dayEnd
    },
    isTodayUK: function () {
      return todayUK >= this.dayStart && todayUK <= this.dayEnd
    },
    metadata: {
      range: 'July 23 - August 22',
      shortRange: 'Jul 23 - Aug 22',
      animal: 'The Lion',
      type: 'Fire',
      planet: 'The Sun',
      blurb: 'Leo is the charismatic and confident ruler of the zodiac. They have a magnetic presence, and their passion for life shines through in everything they do. Leos are natural-born leaders who love being in the spotlight.'
    }
  },
  virgo: {
    id: 6,
    dayStart: new Date(`${thisYear}-08-23T00:00:00.000Z`),
    dayEnd: new Date(`${thisYear}-09-22T23:59:59.000Z`),
    isTodayUS: function () {
      return todayUS >= this.dayStart && todayUS <= this.dayEnd
    },
    isTodayUK: function () {
      return todayUK >= this.dayStart && todayUK <= this.dayEnd
    },
    metadata: {
      range: 'August 23 - September 22',
      shortRange: 'Aug 23 - Sep 22',
      animal: 'The Virgin',
      type: 'Earth',
      planet: 'Mercury',
      blurb: 'Virgo is the practical and analytical perfectionist of the zodiac. They possess a keen eye for detail and excel in organizing and problem-solving. Virgos are reliable friends and are always willing to lend a helping hand.'
    }
  },
  libra: {
    id: 7,
    dayStart: new Date(`${thisYear}-09-23T00:00:00.000Z`),
    dayEnd: new Date(`${thisYear}-10-22T23:59:59.000Z`),
    isTodayUS: function () {
      return todayUS >= this.dayStart && todayUS <= this.dayEnd
    },
    isTodayUK: function () {
      return todayUK >= this.dayStart && todayUK <= this.dayEnd
    },
    metadata: {
      range: 'September 23 - October 22',
      shortRange: 'Sep 23 - Oct 22',
      animal: 'The Scales',
      type: 'Air',
      planet: 'Venus',
      blurb: 'Libra is the harmonious and charming peacemaker of the zodiac. They value balance, fairness, and beauty in all aspects of life. Libras are natural diplomats and seek to create harmony in their relationships.'
    }
  },
  scorpio: {
    id: 8,
    dayStart: new Date(`${thisYear}-10-23T00:00:00.000Z`),
    dayEnd: new Date(`${thisYear}-11-21T23:59:59.000Z`),
    isTodayUS: function () {
      return todayUS >= this.dayStart && todayUS <= this.dayEnd
    },
    isTodayUK: function () {
      return todayUK >= this.dayStart && todayUK <= this.dayEnd
    },
    metadata: {
      range: 'October 23 - November 21',
      shortRange: 'Oct 23 - Nov 21',
      animal: 'The Scorpion',
      type: 'Water',
      planet: 'Pluto',
      blurb: 'Scorpio is the intense and mysterious powerhouse of the zodiac. Their emotions run deep, and they are fiercely loyal to those they care about. Scorpios have a magnetic aura that draws people in, but they also value their privacy.'
    }
  },
  sagittarius: {
    id: 9,
    dayStart: new Date(`${thisYear}-11-22T00:00:00.000Z`),
    dayEnd: new Date(`${thisYear}-12-21T23:59:59.000Z`),
    isTodayUS: function () {
      return todayUS >= this.dayStart && todayUS <= this.dayEnd
    },
    isTodayUK: function () {
      return todayUK >= this.dayStart && todayUK <= this.dayEnd
    },
    metadata: {
      range: 'November 22 - December 21',
      shortRange: 'Nov 22 - Dec 21',
      animal: 'The Archer',
      type: 'Fire',
      planet: 'Jupiter',
      blurb: 'Sagittarius is the adventurous and optimistic explorer of the zodiac. They have a thirst for knowledge, love to travel, and seek the meaning of life. Sagittarians have a contagious sense of humor and are always up for new experiences.'
    }
  },
  capricorn: {
    id: 10,
    dayStart: new Date(`${isJanuary ? lastYear : thisYear}-12-22T00:00:00.000Z`),
    dayEnd: new Date(`${isJanuary ? thisYear : nextYear}-01-19T23:59:59.000Z`),
    isTodayUS: function () {
      return todayUS >= this.dayStart && todayUS <= this.dayEnd
    },
    isTodayUK: function () {
      return todayUK >= this.dayStart && todayUK <= this.dayEnd
    },
    metadata: {
      range: 'December 22 - January 19',
      shortRange: 'Dec 22 - Jan 19',
      animal: 'The Goat',
      type: 'Earth',
      planet: 'Saturn',
      blurb: 'Capricorn is the disciplined and ambitious achiever of the zodiac. They are hardworking, responsible, and determined to climb the ladder of success.Capricorns are practical strategists who value tradition and long-term goals.'
    }
  },
  aquarius: {
    id: 11,
    dayStart: new Date(`${thisYear}-01-20T00:00:00.000Z`),
    dayEnd: new Date(`${thisYear}-02-18T23:59:59.000Z`),
    isTodayUS: function () {
      return todayUS >= this.dayStart && todayUS <= this.dayEnd
    },
    isTodayUK: function () {
      return todayUK >= this.dayStart && todayUK <= this.dayEnd
    },
    metadata: {
      range: 'January 20 - February 18',
      shortRange: 'Jan 20 - Feb 18',
      animal: 'The Water Bearer',
      type: 'Air',
      planet: 'Uranus',
      blurb: 'Aquarius is the eccentric and humanitarian visionary of the zodiac. They are innovative thinkers who value freedom, independence, and equality. Aquarians are dedicated to making the world a better place throughtheir unique perspectives.'
    }
  },
  pisces: {
    id: 12,
    dayStart: new Date(`${thisYear}-02-19T00:00:00.000Z`),
    dayEnd: new Date(`${thisYear}-03-20T23:59:59.000Z`),
    isTodayUS: function () {
      return todayUS >= this.dayStart && todayUS <= this.dayEnd
    },
    isTodayUK: function () {
      return todayUK >= this.dayStart && todayUK <= this.dayEnd
    },
    metadata: {
      range: 'February 19 - March 20',
      shortRange: 'Feb 19 - Mar 20',
      animal: 'The Fishes',
      type: 'Water',
      planet: 'Neptune',
      blurb: 'Pisces is the compassionate and imaginative dreamer of the zodiac. They are highly intuitive, empathetic, and often deeply artistic. Pisceans are known for their emotional depth and ability to connect with others on a profound level.'
    }
  }
}
