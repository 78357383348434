import React from 'react';
import PropTypes from 'prop-types';
import InnerHTML from './InnerHTML';

class Ad extends React.PureComponent {
  render() {
    const {
      code, isInject, className,
    } = this.props;

    if (!code) {
      return null;
    }
    if (isInject) {
      return (
        <InnerHTML className={`advertisement ${className} injected`} html={code} />
      );
    }
    return (
      <div className={`advertisement ${className}`} dangerouslySetInnerHTML={{ __html: code }} />
    );
  }
}

Ad.propTypes = {
  isInject: PropTypes.bool,
  code: PropTypes.string,
  className: PropTypes.string,
};

Ad.defaultProps = {
  isInject: false,
  code: '',
  className: '',
};

export default Ad;
