/* eslint-disable camelcase */
import { createContext, useContext, useState } from 'react';

import {
  createClientEvent,
 } from '@buzzfeed/client-event-tracking';
import {
  content_action,
  // impression,
  external_link,
  internal_link,
  pageview,
  time_spent,
} from '@buzzfeed/client-event-tracking/events';
import { createClientTimeSpentHandler } from '@buzzfeed/client-event-tracking/handlers';
import { PageInfoContext } from '../context/PageInfoContext';
import { CLUSTER } from '../constants';

if (!CLUSTER) {
  console.warn('CLUSTER not set, using fallback');
}

const sendClientEvent = createClientEvent({
  env: CLUSTER,
});

const createClientEventWithCommonData =
  (event_config) =>
  (...layers) => {
    sendClientEvent(event_config, ...layers);
  };

// const trackClientImpression = createClientEventWithCommonData(impression);
const trackClientExternalLink = createClientEventWithCommonData(external_link);
const trackClientInternalLink = createClientEventWithCommonData(internal_link);
const trackClientContentAction =
  createClientEventWithCommonData(content_action);

const trackClientPageview = createClientEventWithCommonData(pageview);
const trackTimeSpent = createClientEventWithCommonData(time_spent);

export const attachTimeSpentHandler = createClientTimeSpentHandler(
  ({ layers }, ...additionalData) => {
    trackTimeSpent(...additionalData, ...layers);
  }
);

function useTracking() {

  const pageInfo = useContext(PageInfoContext);
  const trackingLayer = {
    unit_type: 'feed',
    unit_name: 'main',
  };

  const withPageInfo = (trackingAction) => (eventInfo) =>
    trackingAction({ ...pageInfo, ...eventInfo });


  /**
   * Sends page view event
   *
   * should this only happen once per instance?
   */
  const trackPageView = () => {
    const _track = withPageInfo(trackClientPageview);
    _track();
  };


  /**
   * TRACKING FXNS
   */

  const _tracking = () => {
    return trackingLayer;
  };

  const trackInternalClick = (componentLayer) => {
    withPageInfo(trackClientInternalLink)({
      ..._tracking(),
      ...componentLayer,
    });
  };

  const trackExternalClick = (componentLayer) => {
    withPageInfo(trackClientExternalLink)({
      ..._tracking(),
      ...componentLayer,
    });
  };

  const trackContentAction = (componentLayer) => {
    withPageInfo(trackClientContentAction)({
      ..._tracking(),
      ...componentLayer
    });
  };

  return {
    attachTimeSpentHandler,
    trackingLayer,

    trackInternalClick,
    trackExternalClick,
    trackContentAction,
    trackPageView,

    // trackImpression: withPageInfo(trackClientImpression),
  };
};

export const TrackingContext = createContext();
export default function TrackingProvider({ children }) {
  const tracker = useTracking();
  return (
    <TrackingContext.Provider value={tracker}>
      { children }
    </TrackingContext.Provider>
  );
}
