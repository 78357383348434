export default function Styles({ navdata }) {
  return (
    <>
      {Object.keys(navdata).map((item, i) => (
        <style key={i} rel="stylesheet" suppressHydrationWarning>
          {navdata[item].css}
        </style>
      ))}
    </>
  );
}
