import React from 'react';

function Newsletter({ host, pageId }) {
  return (
    <iframe
      className='newsletter'
      src={`https://${host}/newsletter/signup/horoscopes/embed?theme=life&page_id=huffpost_horoscopes_${pageId}&page_type=feed`}
      width='100%'
      height='430'
      style={{ border: 'none' }}
    />
  );
}

export default Newsletter;
