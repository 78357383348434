import fetch from 'isomorphic-unfetch';
import { settings } from '@buzzfeed/rig';

const fetchResult = async (url) => {
  console.log(`fetching ${url}`)
  let response;
  try {
    response = await fetch(url)
  } catch ( error ) {
    console.error(error)
  }
  return response.json()
}

export async function getNav({ edition }) {
  const hostMap = settings.get('hosts');
  const host = hostMap[edition];

  const url = `https://${host}/api/templates/life/header,leftnav,footer?theme=life`;

  const res = await fetchResult(url)
  return res;
}
