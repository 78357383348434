import signData from '../upstreams/baseSignData';
import { setAssetPath, setWebviewPath } from '../utils/helpers';
import { useContext, useState, useEffect } from 'react';
import { TrackingContext } from '../hooks/useTracking';

import Aquarius from '../public/images/signs/sign-aquarius.svg';
import Aries from '../public/images/signs/sign-aries.svg';
import Cancer from '../public/images/signs/sign-cancer.svg';
import Capricorn from '../public/images/signs/sign-capricorn.svg';
import Gemini from '../public/images/signs/sign-gemini.svg';
import Leo from '../public/images/signs/sign-leo.svg';
import Libra from '../public/images/signs/sign-libra.svg';
import Pisces from '../public/images/signs/sign-pisces.svg';
import Sagittarius from '../public/images/signs/sign-sagittarius.svg';
import Scorpio from '../public/images/signs/sign-scorpio.svg';
import Taurus from '../public/images/signs/sign-taurus.svg';
import Virgo from '../public/images/signs/sign-virgo.svg';

const BREAKPOINT = 900; // when right rail stacks under main content

const SignsModule = ({ page, modifier='rows-of-four', curr = null, isWebview = false }) => {
  const signs = [];
  const [windowDimensions, setWindowDimensions] = useState({
    width: undefined,
    height: undefined
  })
  const isHub = page === 'hub';

  useEffect(() => {
    const handleResize = () => {
      setWindowDimensions({
        width: window.innerWidth,
        height: window.innerHeight
      })
    }
    handleResize()
    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  for (const [sign, data] of Object.entries(signData)) {
    signs.push({
      current: sign === curr ? ' current' : '',
      name: sign,
      date: data.metadata.shortRange,
      blurb: data.metadata.blurb,
      symbolSrc: `/../images/signs/sign-${sign}.svg`,
    });
  }

  const components = {
    aquarius: Aquarius,
    aries: Aries,
    cancer: Cancer,
    capricorn: Capricorn,
    gemini: Gemini,
    leo: Leo,
    libra: Libra,
    pisces: Pisces,
    sagittarius: Sagittarius,
    scorpio: Scorpio,
    taurus: Taurus,
    virgo: Virgo,
  };


  const { trackInternalClick } = useContext(TrackingContext);

  const onClickSign = (e) => {
    const sign = e.target.closest('.cet-parent').dataset.type;
    trackInternalClick({
      unit_name: !isHub && windowDimensions.width > BREAKPOINT ? 'right' : 'main',
      unit_type: !isHub && windowDimensions.width > BREAKPOINT ? 'sidebar' : 'feed',
      subunit_name: 'signs_module',
      subunit_type: 'component',
      item_name: sign,
      item_type: 'image',
      target_content_type: 'feed',
      target_content_id: `huffpost_horoscopes_${sign}`,
      referrer_uri: document.referrer,
    });
  };

  return (
    <div className={`signs-module ${modifier}`}>
      {
        signs.map((sign) => {
          const Image = components[sign.name];
          return (
            <div className='signs-module__container' key={sign.name}>
              <a
                className={`cet-parent signs-module__item${sign.current}`}
                href={setWebviewPath(setAssetPath(`/${sign.name}`), isWebview)}
                onClick={onClickSign}
                data-type={sign.name}
              >
                <div className='signs-module__overlay' />
                  <Image
                    className='signs-module__img'
                    alt={`Symbol for ${sign.name}`}
                    height={50}
                  />
                <h3 className='signs-module__text'>{sign.name}</h3>
                <div className='signs-module__date'>{sign.date}</div>
              </a>
            </div>
          )
        })
      }
    </div>
  );
}

export default SignsModule;
