import { useContext, useEffect } from 'react';
import { PageInfoContext } from '../context/PageInfoContext';
import { TrackingContext } from '../hooks/useTracking';

export function useTimeSpentTracking() {
  const { attachTimeSpentHandler } = useContext(TrackingContext);
  const pageInfo = useContext(PageInfoContext);

  useEffect(() => {
      attachTimeSpentHandler(pageInfo)
    }
  );
}
